import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import ResponsiveImage from 'components/common/ResponsiveImage'
import Link from 'components/common/Link'
import Icon from 'components/common/Icon'
import Text from 'components/common/Text'
import Dropdown from 'components/common/dropdowns/Dropdown'
import { useRouter } from 'next/router'

import getNested from 'utility/getNested'
import { formatPhoneNumber } from 'utility/numbers'
import { useApp } from 'components/providers/AppProvider'
import { useSelf } from 'components/providers/SelfProvider'
import { useTenantProfile } from 'hooks/api/tenant/useTenantProfile'

import colors from 'utility/colors'
import Button from 'components/common/Button'

const StyledLinksRow = styled(Row)`
	vertical-align: middle;
	text-align: right;
	justify-content: flex-end;
	align-items: 'center';
	@media (max-width: 768px) {
		justify-content: center;
	}
`

const NavBarLinkInternal = styled.a(() => {
	const { data: tenantProfile } = useTenantProfile()
	const primaryColor = tenantProfile?.theme?.primaryColor
	const secondaryColor = tenantProfile?.theme?.secondaryColor

	return `
    font-family: 'Raleway' 'Bold';
    color: ${primaryColor} !important;
    font-size: 14px;
    padding-left: 25px;
    font-weight: 700;
    padding-top: 10px;
    padding-bottom: 10px;
    letter-spacing: 0.01px;
    line-height: 18px;
    :hover {
			cursor: pointer;
		}
  `
})

const NavCountdown = styled.p`
	font-family: 'Raleway' 'Bold';
	color: ${colors.PRIMARY_WHITE} !important;
	font-size: 22px;
	font-weight: 700;
	letter-spacing: 0.01px;
	line-height: 30px;
	display: inline;
	/* margin: 0px;
  padding: 0px; */
	text-align: center;
`

const NavCountdownPreface = styled.p`
	font-family: 'Raleway' 'Bold';
	color: ${colors.BLACK} !important;
	font-size: 18px;
	font-weight: 700;
	letter-spacing: 0.01px;
	line-height: 30px;
	display: inline;
	/* margin: 0px; */
	/* padding: 0px; */
	text-align: center;
`

const SignedInDropdown = () => {
	const router = useRouter()
	const { data: tenantProfile } = useTenantProfile()
	const primaryColor = tenantProfile?.theme?.primaryColor
	const { user, isHomeowner, isPartner, isAdmin, logOut, isBlocked } = useSelf()
	const name = user?.fullName?.split(' ')
	let baseOptions = []

	if (isPartner) {
		user?.partnerAccounts.map((partnerAcct) => {
			baseOptions.push({
				title: 'Partner Portal',
				subtitle: partnerAcct?.slug,
				onClick: () => router.push(partnerAcct.link),
			})
		})
	}

	if (isHomeowner) {
		baseOptions.push({
			title: 'Homeowner Portal',
			onClick: () => router.push(user.homeownerSlug),
		})
	} else {
		baseOptions.push({
			title: 'Sign Up My Home',
			style: {
				backgroundColor: primaryColor,
				color: colors.WHITE,
			},
			onClick: () => router.push(`/partners/${user?.primaryPartnerAccount?.slug}/signup`),
		})
	}

	baseOptions.push({
		title: 'Log Out',
		subtitle: `${user.email ? user.email : ''}${isAdmin ? '\nadmin ' : ''}${
			isBlocked ? '\nblocked ' : ''
		}`,
		onClick: logOut,
	})

	return (
		<Dropdown
			style={{ marginLeft: 20, marginBottom: 0 }}
			buttonStyle={{
				marginTop: 20,
				marginBottom: 20,
				padding: 0,
				border: 'none',
				fontWeight: 'bold',
				backgroundColor: colors.WHITE,
			}}
			color={primaryColor}
			options={baseOptions}
		>
			Welcome, {name && name.length ? name[0] : ''}
		</Dropdown>
	)
}

const DefaultNavBar = ({
	navOpen,
	publicPartner,
	setNavOpen,
	hideLogo,
	isSignedIn,
	hideSignup = true,
	withPartnerNav = true,
}) => {
	const router = useRouter()
	const { data: tenantProfile } = useTenantProfile()
	const tenantLogo = tenantProfile?.theme?.colorLogo
	const primaryColor = tenantProfile?.theme?.primaryColor
	const secondaryColor = tenantProfile?.theme?.secondaryColor
	const homepage = tenantProfile?.websiteUrl

	const { breakpoints } = useApp()

	const partner = publicPartner

	const hasBothTeamAndCompanyName =
		getNested(partner, 'companyName') && getNested(partner, 'teamName')
	const hasTeamOrCompanyName = getNested(partner, 'companyName') || getNested(partner, 'teamName')

	const withPartner = partner && withPartnerNav

	return (
		<>
			<Col xs='2' sm='2' className='d-block d-md-none' style={{ marginTop: 2 }}>
				{navOpen ? (
					<Icon icon={'times'} size={24} light onClick={() => setNavOpen(false)} />
				) : (
					<Icon icon={'bars'} size={30} light onClick={() => setNavOpen(true)} />
				)}
			</Col>
			<Col
				xs={withPartner ? '10' : '8'}
				md='6'
				lg='6'
				xl='6'
				className='d-block text-center text-md-left d-md-block'
			>
				{withPartner ? (
					<Row style={{ alignItems: 'center' }}>
						<Col
							xs='12'
							sm='12'
							md='12'
							lg='12'
							xl='12'
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								overflow: 'hidden',
							}}
						>
							<Link
								to={partner ? `/partners/${getNested(partner, 'slug')}` : homepage ? homepage : '/'}
							>
								{getNested(partner, 'avatarImageUrl') ? (
									<div
										style={{
											backgroundImage: `url(${getNested(partner, 'avatarImageUrl')})`,
											backgroundSize: 'cover',
											backgroundPosition: 'center center',
											backgroundRepeat: 'no-repeat',
											marginLeft: -10,
											borderRadius: 4,
											marginRight: 15,
											minWidth: 94,
											minHeight: 94,
										}}
									/>
								) : tenantLogo ? (
									<ResponsiveImage
										src={tenantLogo}
										style={{ width: 70, height: 86, marginRight: 20 }}
									/>
								) : (
									<NavBarLinkInternal id='home' to='/'>
										Home
									</NavBarLinkInternal>
								)}
							</Link>
							<div style={{ marginRight: 40 }}>
								<div>
									<Text size={18} bold style={{ marginBottom: 0, paddingBottom: 0 }}>
										{getNested(partner, 'fullName')}
									</Text>
									{hasTeamOrCompanyName ? (
										<Text size={14} style={{ marginBottom: 0, paddingBottom: 0 }}>
											{getNested(partner, 'companyName') ? getNested(partner, 'companyName') : ''}
											{hasBothTeamAndCompanyName && breakpoints.md ? ' | ' : ''}
											{getNested(partner, 'teamName') && breakpoints.md
												? getNested(partner, 'teamName')
												: ''}
										</Text>
									) : null}
								</div>

								<Text
									size={14}
									color={secondaryColor}
									style={{ marginBottom: 0, paddingBottom: 0 }}
								>
									{getNested(partner, 'emailAddress')}&nbsp;&nbsp;
									<span className='d-none d-sm-inline'>|&nbsp;&nbsp;</span>
									<span className='d-none d-sm-inline-block'>
										{formatPhoneNumber(getNested(partner, 'phoneNumber'))}
									</span>
								</Text>

								{/* ONLY Shows on XS Screens */}
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}
									className='d-flex d-sm-none'
								>
									<Text
										size={14}
										color={secondaryColor}
										style={{ marginBottom: 0, paddingBottom: 0 }}
									>
										{formatPhoneNumber(getNested(partner, 'phoneNumber'))}
									</Text>
								</div>
							</div>
						</Col>
					</Row>
				) : (
					!hideLogo && (
						<Link to={homepage ? homepage : '/'}>
							{tenantLogo ? (
								<ResponsiveImage
									src={tenantLogo}
									style={{
										maxHeight: 55,
										maxWidth: 160,
										marginTop: 4,
										cursor: 'pointer',
									}}
								/>
							) : (
								<NavBarLinkInternal id='home' to={homepage ? homepage : '/'}>
									Home
								</NavBarLinkInternal>
							)}
						</Link>
					)
				)}
			</Col>
			<Col xs='12' md='6' lg='6' xl='6' className='d-none d-md-block'>
				<StyledLinksRow
					style={{
						marginLeft: 20,
						display: 'flex',
						alignItems: 'center',
					}}
				>
					{isSignedIn ? (
						<>
							<SignedInDropdown />
						</>
					) : (
						<>
							<Link to='/auth/signin'>
								<NavBarLinkInternal id='auth' to='/auth/signin'>
									Sign In
								</NavBarLinkInternal>
							</Link>
							<Link to={withPartner ? `/partners/${router.query.slug}/signup` : '/signup'}>
								<Button
									color={colors.WHITE}
									style={{
										width: 110,
										fontSize: 14,
										marginLeft: 20,
										fontWeight: 'bold',
										backgroundColor: primaryColor,
									}}
								>
									Get Started
								</Button>
							</Link>
						</>
					)}
					{hideSignup ? null : (
						<Link to='/signup'>
							<Button
								color={colors.WHITE}
								style={{
									width: 80,
									height: 30,
									marginTop: 4,
									fontSize: 14,
									marginLeft: 20,
									backgroundColor: primaryColor,
								}}
							>
								Get Started
							</Button>
						</Link>
					)}
				</StyledLinksRow>
			</Col>
		</>
	)
}

const MobileSideBar = ({ open, setNavOpen }) => {
	const { isSignedIn, user, isAdmin, isPartner, isHomeowner, logOut } = useSelf()
	const { data: tenantProfile } = useTenantProfile()
	const tenantLogo = tenantProfile?.theme?.colorLogo
	const primaryColor = tenantProfile?.theme?.primaryColor
	const secondaryColor = tenantProfile?.theme?.secondaryColor

	if (!open) return null

	return (
		<>
			<div
				className={'d-flex d-md-none'}
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100vw',
					minHeight: '100vh',
					backgroundColor: 'white',
					zIndex: 9999999999,
				}}
			>
				<div
					style={{
						position: 'absolute',
						top: 20,
						width: '100%',
						textAlign: 'center',
					}}
				>
					<Link to='/'>
						<ResponsiveImage
							src={tenantLogo}
							style={{
								// maxHeight: '47px',
								cursor: 'pointer',
								maxWidth: '300px',
								width: '50%',
							}}
						/>
					</Link>
				</div>
				<div style={{ position: 'absolute', top: 10, left: 20 }}>
					{open ? (
						<Icon icon={'times'} size={24} light onClick={() => setNavOpen(false)} />
					) : (
						<Icon icon={'bars'} size={30} light onClick={() => setNavOpen(true)} />
					)}
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'start',
						flexDirection: 'column',
						width: '100%',
						paddingLeft: 20,
						marginTop: 50,
					}}
				>
					{isSignedIn ? (
						<>
							{isHomeowner && (
								<Link to={user?.homeownerSlug}>
									<Text
										color={primaryColor}
										bold
										size={30}
										style={{ paddingBottom: 10 }}
										onClick={() => setNavOpen(false)}
									>
										Homeowner Portal
									</Text>
								</Link>
							)}
							{isPartner && (
								<Link to={user?.partnerSlug}>
									<Text
										color={primaryColor}
										bold
										size={30}
										style={{ paddingBottom: 10 }}
										onClick={() => setNavOpen(false)}
									>
										Partner Portal
									</Text>
								</Link>
							)}
						</>
					) : (
						<>
							<Link to='/'>
								<Text
									color={primaryColor}
									bold
									size={30}
									style={{ paddingBottom: 10 }}
									onClick={() => setNavOpen(false)}
								>
									Homeowners
								</Text>
							</Link>
							<Link to='/partners'>
								<Text
									color={primaryColor}
									bold
									size={30}
									style={{ paddingBottom: 10 }}
									onClick={() => setNavOpen(false)}
								>
									Partners
								</Text>
							</Link>
						</>
					)}

					<Row style={{ paddingTop: 100 }}>
						{isSignedIn ? (
							<Col xs='12'>
								<Text>{user?.email}</Text>
								{isAdmin ? <Text>admin</Text> : null}
								<Button
									color={colors.WHITE}
									onClick={() => {
										logOut()
										setNavOpen(false)
									}}
									style={{
										width: 200,
										height: 40,
										marginBottom: 10,
										fontSize: 18,
										backgroundColor: secondaryColor,
									}}
								>
									Log Out
								</Button>
							</Col>
						) : (
							<>
								<Col xs='12'>
									<Link to='/auth/signin'>
										<Button
											color={colors.WHITE}
											onClick={() => setNavOpen(false)}
											style={{
												width: 200,
												height: 40,
												marginBottom: 10,
												fontSize: 18,
												backgroundColor: secondaryColor,
											}}
										>
											Sign In
										</Button>
									</Link>
								</Col>
								<Col xs='12'>
									<Link to='/signup'>
										<Button
											color={colors.WHITE}
											onClick={() => setNavOpen(false)}
											style={{
												width: 200,
												height: 40,
												marginBottom: 10,
												fontSize: 18,
												backgroundColor: secondaryColor,
											}}
										>
											Sign Up
										</Button>
									</Link>
								</Col>
							</>
						)}
					</Row>
				</div>
			</div>
		</>
	)
}

const NewNav = ({ hideLogo, partner, hideSignup = true, withPartnerNav = false }) => {
	const { isSignedIn } = useSelf()
	const [navOpen, setNavOpen] = useState(false)

	const hasPartnerOrAgent = partner && Object.keys(partner || {}).length > 0
	partner = hasPartnerOrAgent ? partner : null

	if (partner && !partner?.showProfile) return null

	return (
		<div
			style={{
				boxShadow: '0 2px 10px 0 rgba(44, 44, 44, .1)',
				backgroundColor: 'white',
			}}
		>
			{/* MOBILE NAV */}
			<MobileSideBar open={navOpen} setNavOpen={setNavOpen} />
			<Container style={{ height: partner && withPartnerNav ? 100 : 58 }}>
				<Row style={{ alignItems: 'center', display: 'flex', height: '100%' }}>
					<DefaultNavBar
						isSignedIn={isSignedIn}
						publicPartner={partner}
						navOpen={navOpen}
						hideLogo={hideLogo}
						hideSignup={hideSignup}
						setNavOpen={setNavOpen}
						withPartnerNav={withPartnerNav}
					/>
				</Row>
			</Container>

			{/* Removed for whitelabel for the time being */}
			{/* {!hideCountdown && !isFlashSaleAgent ? (
				<CountdownBanner countdownDate={countdownDate} />
			) : isFlashSaleAgent ? (
				<Container fluid style={{ backgroundColor: colors.YELLOW }}>
					<Row>
						<Col style={{ textAlign: 'center', marginBottom: 10, marginTop: 10 }}>
							<NavCountdownPreface
								style={{
									fontSize: breakpoints.sm ? '18px' : '14px',
									lineHeight: breakpoints.sm ? '30px' : '18px',
									marginLeft: 10,
									marginRight: 10,
								}}
							>
								SALE ENDS
							</NavCountdownPreface>
							<NavCountdown
								style={{
									fontSize: breakpoints.sm ? 22 : 16,
									lineHeight: breakpoints.sm ? '30px' : '18px',
								}}
							>
								<CountdownTimer
									date={new Date().setHours(24, 0, 0, 0)}
									// endTime={}
									hideDays
									color={colors.BLUE}
								/>
							</NavCountdown>
						</Col>
					</Row>
				</Container>
			) : null} */}
		</div>
	)
}

export default NewNav
