import React from 'react'
import styled from 'styled-components'
import { COLORS } from 'utility/colors'

const StyledFormLabel = styled.div`
	font-family: 'Poppins', sans-serif;
	margin-bottom: 3px;
	font-size: 16px;
	font-weight: 500;
	color: ${COLORS.GRAY};
`

const RedAsterik = styled.span`
	color: red;
`

const FormLabel = ({ children, required, style }) => {
	return (
		<StyledFormLabel style={style}>
			{children}{' '}
			{required && (
				<>
					{' '}
					<RedAsterik>*</RedAsterik>
				</>
			)}
		</StyledFormLabel>
	)
}

export default FormLabel
