import Link from 'components/common/Link'
import { Container, Row, Col } from 'react-bootstrap'
import { useTenantProfile } from 'hooks/api/tenant/useTenantProfile'
import ResponsiveImage from 'components/common/ResponsiveImage'
import Text from 'components/common/Text'

export const TenantFooter = () => {
	const { data: tenantProfile } = useTenantProfile()
	const tenantName = tenantProfile?.name
	const tenantLogo = tenantProfile?.theme?.colorLogo
	const primaryColor = tenantProfile?.theme?.primaryColor
	const homepage = tenantProfile?.websiteUrl || '/'

	return (
		<Container
			style={{
				marginTop: 80,
				marginBottom: 40,
				gap: 15,
				flexDirection: 'column',
				display: 'flex',
			}}
		>
			<Row>
				<Col>
					<Link to={homepage}>
						{tenantLogo ? <ResponsiveImage src={tenantLogo} style={{ width: 120 }} /> : null}
					</Link>
				</Col>
			</Row>
			<div>
				<hr />
			</div>

			<div
				style={{
					display: 'flex',
					flex: 1,
					flexDirection: 'row',
					justifyContent: 'space-between',
					flexWrap: 'wrap',
					gap: 10,
				}}
			>
				{/* Get me the current year using date js */}
				<Text size={16}>
					© {new Date().getFullYear()} {tenantName}. All Rights Reserved.
				</Text>
				<div style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
					<Link to='/terms-of-service'>
						<Text color={primaryColor} hoverColor={primaryColor}>
							Terms of Service
						</Text>
					</Link>
					<Link to='/privacy-policy'>
						<Text color={primaryColor} hoverColor={primaryColor}>
							Privacy Policy
						</Text>
					</Link>
					<Link to='/partners'>
						<Text color={primaryColor} hoverColor={primaryColor}>
							For Partners
						</Text>
					</Link>
				</div>
			</div>
		</Container>
	)
}
