import React, { useState, useRef } from 'react'
import Link from 'components/common/Link'

import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import Button from 'components/common/buttons/Button'
import Card from 'components/common/cards/Card'
import DropdownOption from 'components/common/dropdowns/DropdownOption'
import useOnClickOutside from 'hooks/useOnClickOutside'
import colors from 'utility/colors'
import { useTenantProfile } from 'hooks/api/tenant/useTenantProfile'

const Dropdown = ({
	children,
	options,
	defaultTitle = 'Select',
	hideChevron = false,
	style = {},
	disabled = false,
	cardStyle = {},
	buttonStyle = {},
	color = colors.WHITE,
}) => {
	const ref = useRef()
	const [isOpen, setIsOpen] = useState(false)
	useOnClickOutside(ref, () => setIsOpen(false))
	const { data: tenantProfile } = useTenantProfile()
	const primaryColor = tenantProfile?.theme?.primaryColor

	const computedColor = disabled ? colors.DISABLED : color
	const computedCardStyle = {
		position: 'absolute',
		zIndex: 999,
		padding: '10px 0px 10px 0px',
		width: 'fit-content',
		top: '85%',
		right: 0,
		borderRadius: 5,
		...cardStyle,
	}
	let computedButtonStyle = {
		display: 'flex',
		backgroundColor: disabled ? colors.OFF_WHITE : primaryColor,
		justifyContent: 'space-between',
		paddingTop: 10,
		paddingBottom: 10,
		borderRadius: 5,
		border: `1px solid ${colors.BLACK}`,
		paddingLeft: 10,
		paddingRight: 10,
		marginBottom: 10,
		...buttonStyle,
	}

	if (!children && defaultTitle) {
		computedButtonStyle = {
			color: disabled ? colors.GRAY : colors.PLACEHOLDER,
			...computedButtonStyle,
		}
	}

	const hasOptions = options && options.length

	const handleOpen = (isOpen) => {
		if (disabled) return

		setIsOpen(isOpen)
	}

	return (
		<div
			style={{ cursor: 'pointer', position: 'relative', ...style }}
			onClick={() => handleOpen(!isOpen)}
			ref={ref}
		>
			<Button noShadow onClick={() => handleOpen(!isOpen)} style={computedButtonStyle}>
				<div
					style={{
						flex: 1,
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						color: computedColor,
					}}
				>
					{children ? children : defaultTitle}
					{isOpen ? (
						!hideChevron && hasOptions ? (
							<FaChevronUp
								size={10}
								color={computedColor}
								style={{ marginLeft: 15, marginTop: 3 }}
							/>
						) : null
					) : !hideChevron && hasOptions && !disabled ? (
						<FaChevronDown
							size={10}
							color={computedColor}
							style={{ marginLeft: 15, marginTop: 3 }}
						/>
					) : null}
				</div>
			</Button>

			{isOpen && hasOptions ? (
				<Card style={computedCardStyle}>
					{options && options.length
						? options.map((option, index) => (
								<DropdownOption
									rule={option.rule}
									key={index}
									icon={option.icon}
									option={option}
									style={option.style}
									onClick={() => {
										option.onClick && option.onClick(option)
										handleOpen(!isOpen)
									}}
								>
									{option.link ? <Link to={option.link}>{option.title}</Link> : option.title}
								</DropdownOption>
						  ))
						: null}
				</Card>
			) : null}
		</div>
	)
}

export default Dropdown
