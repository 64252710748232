import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { COLORS } from 'utility/colors'

const StyledButton = styled(motion.button)`
	width: 100%;
	height: auto;
	padding-top: 10px;
	padding-bottom: 10px;
	font-family: 'Nunito', sans-serif;
	background-color: ${(props) => props.background};
	border-width: 0px;
	font-size: 16px;
	color: ${(props) => props.color};
	font-weight: 400;
	border-radius: 5px;
	text-align: center;
	cursor: pointer;
	:hover {
		cursor: pointer;
	}
`

const Button = ({
	children,
	background = COLORS.PRIMARY_DARK,
	color = 'white',
	onClick = () => console.log('Clicked'),
	disabled,
	animatePercent = 10,
	...props
}) => {
	const delta = animatePercent / 2
	const shrink = (100 - delta) / 100
	const enlarge = (100 + delta) / 100

	return (
		<StyledButton
			whileHover={{ scale: disabled ? 1 : enlarge }}
			whileTap={{ scale: disabled ? 1 : shrink }}
			color={color}
			onClick={onClick}
			background={disabled ? COLORS.LIGHT : background}
			disabled={disabled}
			{...props}
		>
			{children}
		</StyledButton>
	)
}

export default Button
