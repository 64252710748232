import React, { useEffect } from 'react'
import ReactTooltip from 'react-tooltip'

import styled from 'styled-components'
import colors from 'utility/colors'
import Meta from 'components/common/Meta'
import ResponsiveImage from 'components/common/ResponsiveImage'
import PrimaryLogo from 'assets/logo.png'
import Link from 'components/common/Link'
import Text from 'components/common/Text'
import { Sidebar } from 'components/common/Sidebar'
import { useApp } from 'components/providers/AppProvider'
import Icon from 'components/common/Icon'
import { useSelf } from 'components/providers/SelfProvider'
import { SkeletonPage } from 'components/common/skeletons/SkeletonPage'
import { SignInBlock } from 'components/common/blocks/SignInBlock'
import { useUserNotifications } from 'hooks/api/user/useUser'
import { useRouter } from 'next/router'
import { TenantFooter } from './TenantFooter'
import Navbar from 'components/common/navbars/Navbar'
import { useTenantProfile } from 'hooks/api/tenant/useTenantProfile'
import { usePartner } from 'components/providers/PartnerProvider'

const Background = styled.div`
	background-color: ${(props) => props.backgroundColor};
	height: 100%;
`

const MainContent = ({
	children,
	withPartnerNav = false,
	backgroundColor = colors.BACKGROUND,
	footer = false,
	hideNav = false,
	paddingTop = 0,
	hideCountdown = false,
	countdownDate,
	hideSignup = true,
	loading = false,
	isAuthenticated = true,
}) => {
	const { isSignedIn } = useSelf()
	const { breakpoints } = useApp()

	const { fetchPartner, partner } = usePartner()
	const router = useRouter()
	const slug = router?.query?.slug

	useEffect(() => {
		if (slug) fetchPartner({ slug })
	}, [slug])

	let contentBody = children

	if (loading) {
		contentBody = <SkeletonPage />
	} else if (!isAuthenticated) {
		contentBody = <SignInBlock />
	}

	return (
		<div
			style={{
				flex: 1,
				overflow: 'hidden',
				marginTop: isSignedIn ? (breakpoints.md ? 0 : 70) : 0,
			}}
		>
			<Background backgroundColor={backgroundColor}>
				{hideNav || isSignedIn ? null : (
					<Navbar
						partner={partner?.data?.public}
						hideSignup={hideSignup}
						countdownDate={countdownDate}
						hideCountdown={hideCountdown}
						withPartnerNav={withPartnerNav}
					/>
				)}
				<div style={{ paddingTop: paddingTop }}>{contentBody}</div>
				{footer ? <TenantFooter /> : null}
			</Background>
		</div>
	)
}

const SidebarContent = () => {
	const router = useRouter()
	const { isSignedIn } = useSelf()
	const { breakpoints } = useApp()
	const [open, setOpen] = React.useState(false)
	const { userSlug } = router?.query
	const { data: notifications } = useUserNotifications(userSlug)

	const { data: tenantProfile } = useTenantProfile()
	const tenantLogo = tenantProfile?.theme?.colorLogo

	let width = '100%'
	if (breakpoints.xl) {
		width = 225
	} else if (breakpoints.lg) {
		width = 225
	} else if (breakpoints.md) {
		width = 225
	}

	return (
		<>
			{(breakpoints.md || open) && isSignedIn && (
				<div style={{ width, position: 'relative' }}>
					<Sidebar width={width} onClose={() => setOpen(false)} />
				</div>
			)}
			{!breakpoints.md && !open && isSignedIn && (
				<div
					style={{
						position: 'fixed',
						width: '100%',
						backgroundColor: colors.WHITE,
						boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
						padding: 20,
						zIndex: 999,
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<div onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
						<div style={{ position: 'relative' }}>
							<Icon icon='bars' light />
							{notifications?.length ? (
								<div
									style={{
										position: 'absolute',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										top: -10,
										right: -10,
										width: 20,
										height: 20,
										backgroundColor: 'red',
										borderRadius: 100,
									}}
								>
									<Text
										size={12}
										color='#fff'
										style={{ padding: 0, margin: 0, fontWeight: 'bold' }}
									>
										{notifications?.length}
									</Text>
								</div>
							) : null}
						</div>
					</div>
					<Link to='/'>
						<ResponsiveImage
							src={tenantLogo}
							style={{
								maxHeight: '35px',
								maxWidth: '140px',
								marginTop: 4,
								cursor: 'pointer',
							}}
						/>
					</Link>
				</div>
			)}
		</>
	)
}

const Page = ({ pageTitle, title, hideTooltip = false, withSidebar = true, children, ...rest }) => {
	return (
		<>
			<Meta {...rest} title={pageTitle ? pageTitle : title} />
			<div style={{ display: 'flex' }}>
				{withSidebar && <SidebarContent />}
				<MainContent children={children} {...rest} />
			</div>
			{!hideTooltip ? (
				<ReactTooltip
					class='toolTip'
					delayShow={0}
					multiline={true}
					backgroundColor={colors.OFF_WHITE}
					textColor={colors.PRIMARY_DARK}
				/>
			) : null}
		</>
	)
}

export default Page
