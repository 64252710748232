import React from 'react'
import Card from 'components/common/cards/Card'

const Button = ({ children, ...props }) => {
  return (
    <Card
      style={{ padding: 10, width: 'fit-content', cursor: 'pointer' }}
      {...props}
    >
      {children}
    </Card>
  )
}

export default Button
