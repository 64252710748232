import React from 'react'
import styled from 'styled-components'
import colors from 'utility/colors'
import Text from 'components/common/Text'

const StyledOptionContainer = styled.div`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  ${(props) =>
    props.disabled
      ? `
      background-color: ${colors.DISABLED};
      height: 1px;
      width: '100%';
      margin-left: 15px;
      margin-right: 15px;
    `
      : `
      padding: 8px 15px;
      :hover{
        background-color: ${colors.OFF_WHITE}
      }
    `}
`

const StyledOptionRow = styled.div`
  display: flex;
  white-space: nowrap;
  ${(props) =>
    props.disabled
      ? `
      background-color: ${colors.DISABLED};
      height: 1px;
      width: '100%';
      margin-left: 15px;
      margin-right: 15px;
    `
      : ''}
`

const StyledIcon = styled.span`
  margin-right: 10px;
`

const DropdownOptions = ({ icon, option, style, onClick, rule, children }) => {
  if (rule) return <StyledOptionRow disabled={true} />
  return (
    <StyledOptionContainer
      style={option.style}
      onClick={() => onClick && onClick()}
    >
      <StyledOptionRow>
        {icon ? <StyledIcon>{icon}</StyledIcon> : null}
        {children}
      </StyledOptionRow>
      {option?.subtitle && (
        <Text size={10} style={{ margin: 0, padding: 0 }}>
          {option.subtitle}
        </Text>
      )}
    </StyledOptionContainer>
  )
}

export default DropdownOptions
