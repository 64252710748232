import React from 'react'
import styled from 'styled-components'
import FormLabel from 'components/common/forms/elements/FormLabel'
import FieldError from 'components/common/FieldError'
import Icon from 'components/common/Icon'
import Tooltip from 'components/common/Tooltip'
import colors from 'utility/colors'

const FormContainer = styled.div`
	margin-bottom: 15px;
`

const StyledFormInput = styled.input`
	width: 100%;
	height: 40px;
	border-radius: 3px;
	padding-left: 10px;
	box-shadow: 0 2px 20px 0 rgba(91, 91, 91, 0.1);
	border: 1px solid #fff;
`

const StyledCheckboxInput = styled.input`
	width: 20px;
	height: 20px;
	border-radius: 3px;
	padding-left: 10px;
	min-width: 15px;
	box-shadow: 0 2px 20px 0 rgba(91, 91, 91, 0.1);
	border: 1px solid #fff;
`

const StyledCheckboxLabel = styled.label`
	height: 100%;
`

const StyledRadioLabel = styled.label`
	position: absolute;
	margin-top: 2px;
	margin-left: 7px;
`

const StyledRadioInput = styled.input`
	width: 20px;
	height: 20px;
	border-radius: 3px;
	padding-left: 10px;
	box-shadow: 0 2px 20px 0 rgba(91, 91, 91, 0.1);
	border: 1px solid #fff;
`

const FormInput = ({
	label,
	error,
	labelStyle,
	tooltip,
	hint,
	errorStyle = {},
	children,
	value = '',
	...props
}) => {
	let FormComponent = StyledFormInput
	if (props.type === 'checkbox') FormComponent = StyledCheckboxInput
	else if (props.type === 'radio') FormComponent = StyledRadioInput

	const tooltipComponent = (
		<Tooltip text={tooltip}>
			<Icon
				icon='info-circle'
				color={colors.GRAY}
				size={14}
				style={{ position: 'relative', left: props.required ? 15 : 5 }}
			/>
		</Tooltip>
	)

	const isCheckOrRadio = props.type === 'checkbox' || props.type === 'radio'

	return (
		<FormContainer style={{ ...props.formContainerStyle }}>
			{label && props.type !== 'checkbox' && props.type !== 'radio' ? (
				<>
					<FormLabel
						style={labelStyle}
						required={props.required}
						htmlFor={props.name ? props.name : ''}
					>
						{label}
						{tooltip && tooltipComponent}
					</FormLabel>
				</>
			) : null}
			{hint && <p style={{ marginBottom: 5 }}>{hint}</p>}

			<FormComponent
				value={value || ''}
				checked={props.type === 'checkbox' && value === true ? true : undefined}
				{...props}
			>
				{children}
			</FormComponent>
			{label && props.type === 'checkbox' && (
				<div>
					<StyledCheckboxLabel htmlFor={props.name} style={labelStyle}>
						{label}
						{tooltip && tooltipComponent}
						{error ? <FieldError style={errorStyle}>{error}</FieldError> : null}
					</StyledCheckboxLabel>
				</div>
			)}
			{label && props.type === 'radio' ? (
				<div>
					<StyledRadioLabel style={labelStyle} htmlFor={props.name}>
						{label}
						{tooltip && tooltipComponent}
						{error ? <FieldError style={errorStyle}>{error}</FieldError> : null}
					</StyledRadioLabel>
				</div>
			) : null}
			{error && !isCheckOrRadio ? <FieldError style={errorStyle}>{error}</FieldError> : null}
		</FormContainer>
	)
}

export default FormInput
